var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a-entity\n                    look-at=\"[gps-camera]\"\n                    id=\"sound-entity\"\n                    sound=\"src: #sound\"\n                    emitsevent\n                    autoplay=\"false\"\n                    gps-entity-place=\"latitude: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"latitude") : depth0), depth0))
    + "; longitude: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"longitude") : depth0), depth0))
    + ";\"\n                ></a-entity>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!doctype html>\n<html>\n    <head>\n        <meta charset=\"utf-8\" />\n        <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\" />\n        <script src=\"https://aframe.io/releases/1.0.4/aframe.min.js\"></script>\n        <script src=\"https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js\"></script>\n        <script src=\"https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js\"></script>\n    </head>\n\n    <body style=\"margin: 0; overflow: hidden;\">\n        <a-scene\n            embedded\n            loading-screen=\"enabled: false;\"\n            arjs=\"sourceType: webcam; debugUIEnabled: false;\"\n        >\n            <a-assets>\n                <a-asset-item\n                    id=\"sound\"\n                    src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"assetPath") || (depth0 != null ? lookupProperty(depth0,"assetPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"assetPath","hash":{},"data":data,"loc":{"start":{"line":20,"column":25},"end":{"line":20,"column":38}}}) : helper)))
    + "\"\n                    response-type=\"arraybuffer\"\n                ></a-asset-item>\n            </a-assets>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"assetParam") : depth0)) != null ? lookupProperty(stack1,"locations") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":34,"column":21}}})) != null ? stack1 : "")
    + "\n            <a-camera gps-camera rotation-reader></a-camera>\n        </a-scene>\n    </body>\n</html>\n";
},"useData":true});