var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!doctype html>\n<html>\n    <head>\n        <script src=\"https://aframe.io/releases/1.0.4/aframe.min.js\"></script>\n        <script src=\"https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar.js\"></script>\n        <script src=\"https://raw.githack.com/AR-js-org/studio-backend/master/src/modules/marker/tools/gesture-detector.js\"></script>\n        <script src=\"https://raw.githack.com/AR-js-org/studio-backend/master/src/modules/marker/tools/gesture-handler.js\"></script>\n        <script>\n            AFRAME.registerComponent('videohandler', {\n                init: function () {\n                    var marker = this.el;\n                    this.vid = document.querySelector(\"#vid\");\n\n                    marker.addEventListener('markerFound', function () {\n                        this.toggle = true;\n                        this.vid.play();\n                    }.bind(this));\n\n                    marker.addEventListener('markerLost', function () {\n                        this.toggle = false;\n                        this.vid.pause();\n                    }.bind(this));\n                },\n            });\n        </script>\n    </head>\n\n    <body style=\"margin: 0; overflow: hidden;\">\n        <a-scene\n            vr-mode-ui=\"enabled: false\"\n            loading-screen=\"enabled: false;\"\n            arjs='sourceType: webcam; debugUIEnabled: false;'\n            id=\"scene\"\n            embedded\n            gesture-detector\n        >\n            <a-assets>\n                <video\n                    id=\"vid\"\n                    src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"assetPath") || (depth0 != null ? lookupProperty(depth0,"assetPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"assetPath","hash":{},"data":data,"loc":{"start":{"line":40,"column":25},"end":{"line":40,"column":38}}}) : helper)))
    + "\"\n                    preload=\"auto\"\n                    response-type=\"arraybuffer\"\n                    loop\n                    crossorigin\n                    webkit-playsinline\n                    autoplay\n                    muted\n                    playsinline\n                ></video>\n            </a-assets>\n\n            <a-marker\n                type=\"pattern\"\n                preset=\"custom\"\n                url=\"assets/marker.patt\"\n                videohandler\n                smooth=\"true\"\n                smoothCount=\"10\"\n                smoothTolerance=\"0.01\"\n                smoothThreshold=\"5\"\n                raycaster=\"objects: .clickable\"\n                emitevents=\"true\"\n                cursor=\"fuse: false; rayOrigin: mouse;\"\n                id=\"markerA\"\n            >\n                <a-video\n                    src=\"#vid\"\n                    scale=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"assetParam") : depth0)) != null ? lookupProperty(stack1,"scale") : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"assetParam") : depth0)) != null ? lookupProperty(stack1,"scale") : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"assetParam") : depth0)) != null ? lookupProperty(stack1,"scale") : stack1), depth0))
    + "\"\n                    position=\"0 0.1 0\"\n                    rotation=\"-90 0 0\"\n                    class=\"clickable\"\n                    gesture-handler\n                ></a-video>\n            </a-marker>\n\n            <a-entity camera></a-entity>\n        </a-scene>\n    </body>\n</html>\n";
},"useData":true});